import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import Styles from "./Overview.module.scss";
import _ from "lodash"
import { HasPermission } from "components/HasPermission/HasPermission";
import { useAppSelector } from "redux/hooks";
import PriceTooltip from "components/Ui/PriceTooltip";

const QuoteTotal = ({ job, fromLongQuote = false }: any) => {
  const { services } = job;

  const userData: any = useAppSelector((state) => state.auth.entities);
  const tax_type = _.get(userData, 'organisation.configuration.tax_type', 0)

  const mobile_price = _.get(job, 'mobile_price', 0.00)
  const mobile_install = _.get(job, 'mobile_install', false)
  const salesTax = _.get(job, 'sales_tax', 0.00)

  let installPrice: any = 0.00;
  let price: any = mobile_install ? Number(mobile_price) : 0.00;
  let discount_price: any = mobile_install ? Number(mobile_price) : 0.00;
  let hasDiscount: boolean = false
  services.forEach((service: any) => {
    price += service.price
    installPrice += (service.discount) ? (service.discount_price ? service.discount_price : service.price) : service.price
    discount_price += (service.discount) ? (service.discount_price ? service.discount_price : service.price) : service.price;
    if (hasDiscount === false) {
      hasDiscount = _.get(service, 'discount', false)
    }
  });

  if (tax_type === 'add_tax') {
    if (hasDiscount) {
      discount_price += salesTax
    } else {
      price += salesTax
    }
  }

  const totals = [
    {
      type: 'Mobile Charge',
      price: mobile_price?.toFixed(2),
      is_display: mobile_install,
      font_color: '#000000',
      show_tooltip: false
    },
    {
      type: 'Install',
      price: installPrice?.toFixed(2),
      is_display: mobile_install,
      font_color: '#000000',
      show_tooltip: false
    },
    {
      type: hasDiscount ? 'Before Discount' : 'Total',
      price: price?.toFixed(2),
      is_display: true,
      font_color: '#000000',
      is_grand_total: !hasDiscount,
      show_tooltip: true
    },
    {
      type: 'Discounted Price',
      price: discount_price?.toFixed(2),
      is_display: hasDiscount,
      font_color: '#FF6969',
      is_grand_total: hasDiscount,
      show_tooltip: true
    },
  ]

  return <>
    {(fromLongQuote === true) ? <Box className={Styles.serviceLongQuotePrice} justifyContent={'flex-end !important'}>
      {/* <HasPermission permission={['enable_ability_to_record_payments']}>
        <Button variant="text" className={Styles.createNewItem}>Take Payment</Button>
      </HasPermission> */}
      <Box display={'flex'} gap={'15px'}>
        {hasDiscount ? <>
          <Box display={'flex'} gap={'8px'} alignItems={'center'}>
            <Typography variant="title3" fontWeight={500}>
              Before Price
            </Typography>
            <Typography variant="title2" fontWeight={700}>
              ${price?.toFixed(2)}
            </Typography>
          </Box>
          <Box display={'flex'} gap={'8px'} alignItems={'center'}>
            <Typography variant="title3" fontWeight={500}>
              Discounted Price
            </Typography>
            <Typography variant="title2" fontWeight={700} color={'#FF6969'}>
              ${discount_price?.toFixed(2)}
            </Typography>
          </Box>
        </> :
          <Box display={'flex'} gap={'8px'} alignItems={'center'}>
            <Typography variant="title3" fontWeight={500}>
              Total
            </Typography>
            <Typography variant="title2" fontWeight={700}>
              ${price?.toFixed(2)}
            </Typography>
          </Box>
        }
      </Box>
    </Box> : <Box pt={2}>
      <Table className={Styles.table}>
        <TableBody className={Styles.tableBody}>
          {totals.filter(total => total.is_display === true).map((total: any, index: number) => (
            <TableRow
              key={`total_${index}`}
              sx={{ "& td": { border: 0, padding: 0 } }}>
              <TableCell width={'85%'} align="right">
                <Typography
                  variant="subtitle1"
                  className={Styles.servicePriceTitle}
                >{total.type} {total.show_tooltip && <PriceTooltip position={'right'} />}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="subtitle1"
                  className={total.is_grand_total === true ? Styles.servicePrice : Styles.serviceSubPrice}
                  color={total.font_color}
                >${total.price}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>}
  </>
};

export default QuoteTotal;