import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import QuickQuoteAPI from 'api/resources/quickQuote';
import ContactIcon from 'assets/icon/icon _call.svg';
import LocationIcon from 'assets/icon/icon_location.svg';
import EnailIcon from 'assets/icon/icon_sms.svg';
import UserIcon from 'assets/icon/icon_user.svg';
import classNames from 'classnames';
import { PrimaryButton } from 'components/Ui/Button/Button';
import { Tag } from "components/Ui/Button/Tage";
import _ from 'lodash';
import { useEffect, useState } from 'react';
import styles from "./ContactForm.module.scss";
import { getFullAddress } from 'utils';

function SuggestedContactPopup({
  setSuggestedContact,
  suggestedContact,
  suggestedContacts,
  onSelectContactChange,
  handleUseContact,
  buttonText
}: any) {
  const [loading, setLoading] = useState(false);
  const [suggestedQuotes, setSuggestedQuotes] = useState<any>([]);

  const renderAddressField = (value: any, comma: any) => (value ? `${value}${comma}` : '');
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const filter = {
        contactId: suggestedContact.id
      };
      try {
        const response = await QuickQuoteAPI.list(filter);

        setSuggestedQuotes(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    if (suggestedContact) {
      fetchData();
    }
  }, [suggestedContact]);

  return <Dialog
    open={suggestedContact !== null || false}
    onClose={() => setSuggestedContact(null)}
    maxWidth='xs'
    fullWidth={true}
    className={styles.exitDialog}
    disableEnforceFocus
    scroll="paper">
    <DialogTitle>
      <CloseIcon className={classNames(styles.closeButton, styles.closeIcon)} onClick={() => setSuggestedContact(null)} />
      <Typography variant="h6" component={'p'} className={styles.modelPopupTitle}>We've identified a potential match in our system. Is this your customer?</Typography>
    </DialogTitle>
    <DialogContent>
      {suggestedContact && (
        <>
          <div className={styles.contactSelect}>
            <Select
              className={styles.inputFieldSelect}
              labelId={`contact_${suggestedContact?.id}`}
              value={suggestedContact?.id}
              onChange={onSelectContactChange}
              variant='outlined'
            >
              {suggestedContacts.map((item: any, index: number) =>
                <MenuItem key={index} value={item.id}>{item.name} {item.phone_number}</MenuItem>
              )}
            </Select>
          </div>

          <div className={styles.contactDetails}>
            <div>
              <div className={styles.infoWrapper}>
                <div className={styles.infoIcon}>
                  <img src={UserIcon} alt='' />
                </div>
                <div className={styles.info}>
                  <Typography variant="subtitle1" className={styles.infoTitle}>
                    {suggestedContact.name}
                  </Typography>
                </div>
              </div>
              <div className={styles.infoWrapper}>
                <div className={styles.infoIcon}>
                  <img src={ContactIcon} alt='' />
                </div>
                <div className={styles.info}>
                  <Typography variant="subtitle1" className={styles.infoTitle}>
                    {suggestedContact.phone_number}
                  </Typography>
                </div>
              </div>
              <div className={styles.infoWrapper}>
                <div className={styles.infoIcon}>
                  <img src={EnailIcon} alt='' />
                </div>
                <div className={styles.info}>
                  <Typography variant="subtitle1" className={styles.infoTitle}>
                    {suggestedContact.email}
                  </Typography>
                </div>
              </div>
              {!_.isEmpty(suggestedContact.address) &&
                <div className={styles.infoWrapper}>
                  <div className={styles.infoIcon}>
                    <img src={LocationIcon} alt='' />
                  </div>
                  <div className={styles.info}>
                    <Typography variant="subtitle1" className={styles.infoTitle}>
                      {getFullAddress(suggestedContact.address)}
                    </Typography>
                  </div>
                </div>
              }
            </div>
          </div>
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <div>
              {suggestedQuotes.map(
                (quote: any, index: number) =>
                  quote.status === 'has_project' && (
                    <div key={index} className={styles.quoteWrapper}>
                      {quote.status === 'started' && (
                        <div>
                          <Typography variant="caption">
                            Job not added yet
                          </Typography>
                        </div>
                      )}
                      <div className={styles.quoteInfo}>
                        <div>
                          {quote.jobs[0]?.type === 'Automotive' &&
                            <Typography variant="subtitle2" className={styles.infoCaption}>
                              {quote.jobs[0]?.make}{' '}
                              {quote.jobs[0]?.model}
                              ({quote.jobs[0]?.year})
                            </Typography>
                          }
                          {quote.jobs[0]?.type === 'Architectural' &&
                            <Typography variant="subtitle2" className={styles.infoCaption}>
                              {quote.jobs[0]?.building_type} {quote.jobs[0]?.service_type}
                            </Typography>
                          }
                        </div>
                        <div>
                          <Tag label={quote.jobs[0]?.status} className={quote.jobs[0]?.status} />
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          )}
        </>
      )}
    </DialogContent>
    <DialogActions className={styles.exitDialogActions}>
      <PrimaryButton
        fullWidth
        className={styles.useContactBtn}
        onClick={handleUseContact}
        variant="contained"
      >{buttonText}</PrimaryButton>
    </DialogActions>
  </Dialog>
}


export default SuggestedContactPopup;