import { Avatar, Badge, Box, Menu, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { themeColor } from "theme/color";
import styles from "./Header.module.scss";

function Notifications({ data, clearNotifications }: any) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClick = (data: any) => {
    console.log(data)
  }

  return (
    <>
      <Badge
        color="error"
        overlap="circular"
        badgeContent={data.length}
        sx={{
          "& .MuiBadge-badge": { height: 17, minWidth: 17, fontSize: "11px" },
        }}
      >
        <Avatar onClick={handleClick} sx={{ bgcolor: themeColor.gray }}>
          <img
            src="/assets/images/icons/notification.svg"
            width={25}
            alt="menu icon"
          />
        </Avatar>
      </Badge>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            width: '432px',
          },
          sx: {
            '& ul': {
              background: '#fff',
              paddingRight: '10px !important'
            },
            borderRadius: "8px",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 16,
              height: 16,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box gap={1} className={styles.flexCenter} justifyContent={'space-between'} sx={{ padding: '12px 19px', marginRight: '12px', borderBottom: '1px solid #F6F6F6' }}>
          <Box gap={1} className={styles.flexCenter}>
            <img
              src="/assets/images/icons/notification.svg"
              alt="handshake"
              style={{ filter: "brightness(0%)" }}
            />
            <Typography variant="title1" color="black" fontWeight={500}>
              Notifications ({data.length})
            </Typography>
          </Box>
        </Box>
        <Box className={styles.menuScroll}>
          {data.length > 0 ? (
            data.map((item: any, index: any) => (
              <Box
                className={styles.flexCenter}
                sx={{ cursor: "pointer", "&:hover": { background: "rgba(0, 0, 0, 0.04)" } }}
                justifyContent={"space-between"}
                key={index}
                p={'7px 19px'}
                borderBottom={`${data.length === index + 1 ? "0px" : "1px"} solid #F6F6F6`}
                onClick={() => handleNotificationClick(item)}
              >
                <Box className={styles.flexInner} gap={1.5}>
                  <Box textAlign="center">
                    <Typography fontWeight={700} variant="title3" fontSize={'15px'}>
                      {item.time}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="title2"
                      fontWeight={600}
                      fontSize={'15px'}
                      color="#000"
                      lineHeight={'138%'}
                      dangerouslySetInnerHTML={{ __html: item.data.message }}
                      className={styles.textOverflow}
                      title={item.data.message}
                    />
                    <Typography
                      variant="title4"
                      color={'#2E2E2E'}
                      className={styles.textOverflow}
                      fontSize={'11px'}
                      fontWeight={'500'}
                      dangerouslySetInnerHTML={{ __html: item.data['sub-message'] }}
                      title={item.data['sub-message']}
                    />
                  </Box>
                </Box>
              </Box>
            ))
          ) : (
            <Box py={3}>
              <Typography
                textAlign="center"
                fontWeight="bold"
                variant="title2"
                display="block"
              >
                No Notification
              </Typography>
            </Box>
          )}
        </Box>
      </Menu>
    </>
  );
}

export default Notifications;
