import _ from 'lodash';
import { createSelector } from 'reselect';

// Select entities from state
export const getQuickQuoteData = (state: any) => state.quickQuote.data;
export const getQuickQuoteState = (state: any) => state.quickQuote;

//= ====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getQuickQuote = createSelector(
  getQuickQuoteData,
  (data) => {
    return {
      status: data.status,
      id: data.id,
      contact: {
        ...data.contact,
        discoveries: data.contact?.discovery ? _.get(data, 'contact.discovery', []) : _.get(data, 'contact.discoveries', []),
        receive_email: true,
        is_business: (data.contact?.company) ? true : data.contact?.is_business,
        mobile_install: _.get(data, 'jobs[0].mobile_install', false)
      },
      jobs: _.get(data, 'jobs', []).map((job: any) => {
        return { ...job }
      }),
      timeline_history: _.get(data, 'timeline_history', {}),
      appointment: _.get(data, 'appointment', {}),
      notes: _.get(data, 'notes', []),
      amount: _.get(data, 'amount', {}),
      message_for_customer: _.get(data, 'message_for_customer', '')
    }
  }
);

export const selectQuickQuoteInfo = createSelector(getQuickQuoteState, (data) => {
  return {
    loading: data.loading,
    is_quick_quote_open: data.is_quick_quote_open,
    active_step: data.active_step,
    completed_steps: data.completed_steps,
    schedule_job: data.schedule_job,
    quote_project_id: data.project_id,
    job_status: data.job_status,
    max_steps_completed: data.max_steps_completed
  }
})
